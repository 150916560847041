<template>
  <main class="models-page">
    <section
      class="border-bottom bg-lighter md-up:sticky-top"
      style="top: 70px;"
    >
      <div class="container py-3">
        <nav class="nav sm-down:flex-column">
          <span
            @click="inviteAdmin"
            class="btn btn-sm btn-light text-left d-block sm-down:w-fill"
          >
            <i class="i-user-check float-left"></i>
            <span>{{ $t("set_owner") }}</span>
          </span>
          <a
            :href="
              $store.state.isProd
                ? 'https://webarr.arround.business/' + id
                : 'https://webarr.gora.studio/' + id
            "
            target="_blank"
            class="btn btn-sm btn-light text-left d-block sm-down:w-fill"
          >
            <i class="i-globe"></i>
            <span>{{ $t("business_public_page") }}</span>
          </a>
        </nav>
      </div>
    </section>

    <section>
      <div class="container py-5">
        <div class="row">
          <div class="md-down:col-12 lg:col-5 xl:col-3">
            <mega-card>
              <div class="card-body">
                <mega-image class="rounded" ratio="1x1" :src="preview" />
              </div>

              <nav class="nav flex-column">
                <div class="nav-text w-100 text-muted">
                  <i class="i-globe"></i>
                  <span>{{ arp["name"] }}</span>
                  <!--                                  <span>{{round['a_time'] | u2d("MMMM DD YYYY - LT")}}</span>-->
                </div>

                <a
                  :href="arp['site']"
                  class="nav-link"
                  :class="{ 'events-disable text-dark': !arp['site'] }"
                >
                  <i class="i-link"></i>
                  <span>{{ arp["site"] || "- - -" }}</span>
                </a>

                <a
                  :href="'tel:' + phone"
                  class="nav-link"
                  :class="{ 'events-disable text-dark': !phone }"
                >
                  <i class="i-phone"></i>
                  <span>{{ phone || "- - -" }}</span>
                </a>
              </nav>

              <div class="card-body">
                <p class="mb-0" style="word-wrap:break-word; line-height: 1.3;">
                  {{ arp.description }}
                </p>
              </div>
            </mega-card>

            <h4 class="bold text-muted text-dark mb-4">
              {{ $t("business_owner") }}
            </h4>
            <mega-card>
              <div class="card-body">
                <div>
                  <router-link
                    :to="{ name: 'User', params: { id: owner.idt_user } }"
                    class="w-100 navbar-label mr-auto text-dark"
                  >
                    <mega-image
                      class="rounded-full navbar-avatar mr-3"
                      small
                      ratio="1x1"
                      :src="owner.avatar"
                      style="overflow:hidden;"
                    />
                    <div class="">
                      <div>
                        {{ owner.name || "Noname" }}
                      </div>
                      <small>
                        {{ owner.email }}
                      </small>
                    </div>
                  </router-link>
                </div>
              </div>
            </mega-card>

            <h4
              v-if="arp.admins.admins.length > 0"
              class="bold text-muted text-dark mb-4"
            >
              {{ $t("business_admins") }}
            </h4>
            <mega-card v-if="arp.admins.admins.length > 0">
              <div class="card-body">
                <div
                  v-for="(admin, i) in arp.admins.admins"
                  :key="i"
                  :class="{
                    'mb-4':
                      arp.admins.admins.length > 1 &&
                      i + 1 < arp.admins.admins.length
                  }"
                >
                  <router-link
                    :to="{ name: 'User', params: { id: admin.idt_user } }"
                    class="w-100 navbar-label mr-auto text-dark"
                  >
                    <mega-image
                      class="rounded-full navbar-avatar mr-3"
                      small
                      ratio="1x1"
                      :src="admin.avatar"
                      style="overflow:hidden;"
                    />
                    <div class="">
                      <div>
                        {{ admin.name || "Noname" }}
                      </div>
                      <small>
                        {{ admin.username }}
                      </small>
                    </div>
                  </router-link>
                </div>
              </div>
            </mega-card>

            <h4 class="bold text-muted text-dark mb-4">
              {{ $t("qr_code") }}
            </h4>

            <qr-code :uri="qrParams" />

            <mega-button
              v-if="arp.blocked"
              class="btn-primary rounded-0 w-100"
              @click="toggleArrActive"
              >{{ $t("business_block_activate") }}</mega-button
            >
            <mega-button
              v-else
              class="btn-danger rounded-0 w-100"
              @click="toggleArrActive"
              >{{ $t("business_block_deactivate") }}</mega-button
            >
          </div>

          <div class="md-down:col-12 lg:col-7 xl:col-9">
            <section class="no-select mb-5">
              <div class="d-flex mb-4">
                <h4 class="bold text-muted text-dark mb-0">
                  {{ $t("activity") }}
                </h4>
              </div>

              <!-- <div class="row">
                <div class="sm-down:col-12 md:col-6 lg:col-12 xl:col-6">
                  <mega-card class="rounded" :loading="!rounds.loaded">
                    <div class="card-body">
                      <h3
                        class="strong text-success mb-0"
                        style="line-height: 1"
                      >
                        {{ rounds.count }}
                      </h3>
                      <span class="text-muted">{{ $t("rounds") }}</span>
                    </div>

                    <div class="card-media rounded-bottom">
                      <mega-chart
                        ratio="10x3"
                        :labels="Object.keys(dataset.rounds)"
                        clear="grid"
                        :offset="10"
                      >
                        <mega-chart-set
                          :title="$t('rounds')"
                          :data="Object.values(dataset.rounds)"
                          color="success"
                          fill
                        />
                      </mega-chart>
                    </div>
                  </mega-card>
                </div>

                <div class="sm-down:col-12 md:col-6 lg:col-12 xl:col-6">
                  <mega-card class="rounded" :loading="!rounds.loaded">
                    <div class="card-body">
                      <h3
                        class="strong text-warning mb-0"
                        style="line-height: 1"
                      >
                        {{ models.count }}
                      </h3>
                      <span class="text-muted">{{ $t("models") }}</span>
                    </div>

                    <div class="card-media rounded-bottom">
                      <mega-chart
                        ratio="10x3"
                        :labels="Object.keys(dataset.models)"
                        clear="grid"
                        :offset="10"
                      >
                        <mega-chart-set
                          :title="$t('sessions')"
                          :data="Object.values(dataset.models)"
                          color="warning"
                          fill
                        />
                      </mega-chart>
                    </div>
                  </mega-card>
                </div>
              </div> -->

              <div class="row">
                <div class="sm-down:col-12 md:col-6 lg:col-12 xl:col-6">
                  <chart
                    endpoint="/stat/arp/recognitions"
                    :idt_arp="id"
                    chartName="ref_detects"
                    :doubleGraph="false"
                  />
                </div>

                <div class="sm-down:col-12 md:col-6 lg:col-12 xl:col-6">
                  <chart
                    endpoint="/stat/arp/transitions"
                    :idt_arp="id"
                    chartName="arr_transitions"
                    :doubleGraph="false"
                  />
                </div>
              </div>

              <div class="row">
                <div class="sm-down:col-12 md:col-6 lg:col-12 xl:col-6">
                  <chart
                    endpoint="/stat/arp/roundviews"
                    :idt_arp="id"
                    chartName="round_views"
                    :doubleGraph="false"
                  />
                </div>

                <div class="sm-down:col-12 md:col-6 lg:col-12 xl:col-6">
                  <chart
                    endpoint="/stat/arp/roundlikes"
                    :idt_arp="id"
                    chartName="round_likes"
                    :doubleGraph="false"
                  />
                </div>
              </div>
            </section>

            <section class="mb-4" v-if="rounds.loaded && rounds.count > 0">
              <div class="d-flex">
                <h4 class="bold text-muted text-dark">
                  {{ $t("last_rounds") }}
                </h4>
                <router-link
                  class="btn btn-sm ml-auto"
                  :to="{ name: 'Business_Rounds', params: { arr_id: id } }"
                  >{{ $t("view_all") }}</router-link
                >
              </div>

              <mega-slider class="row" :options="slider">
                <mega-slide v-for="(round, i) in rounds.list" :key="i">
                  <div class="px-3">
                    <round-card class="cursor-grab" :data="round" />
                  </div>
                </mega-slide>
              </mega-slider>
            </section>

            <section v-if="models.loaded && models.count > 0">
              <div class="d-flex">
                <h4 class="bold text-muted text-dark">
                  {{ $t("last_models") }}
                </h4>
                <router-link
                  class="btn btn-sm ml-auto"
                  :to="{
                    name: 'Models',
                    params: { roundType: '3d', is_active: 'active' },
                    query: { arr: id }
                  }"
                  >{{ $t("view_all") }}</router-link
                >
              </div>

              <mega-slider class="row" :options="slider">
                <template v-for="(model, i) in models.list">
                  <mega-slide v-if="model.modifications" :key="i">
                    <div class="px-3">
                      <model-card class="cursor-grab" :data="model" />
                    </div>
                  </mega-slide>
                </template>
              </mega-slider>
            </section>
          </div>
        </div>
      </div>
    </section>

    <arr-invite ref="arr-invite" />
  </main>
</template>

<script>
import RoundCard from "../Rounds/components/round-card";
import Chart from "../../components/Chart";
import ModelCard from "../Models/components/model-card";
import ArrInvite from "./components/invite";
import QrCode from "./../../components/QrCode";
import locale from "element-ui/lib/locale";
import "element-ui/lib/theme-chalk/button.css";
import ru from "element-ui/lib/locale/lang/ru-RU";
import en from "element-ui/lib/locale/lang/en";
import {
  formatStartDate,
  formatEndDate,
  dataMapper,
  months,
  getStartDate,
  getEndDate,
  getDatasetKeys,
  formatDate
} from "../../../scripts/statsMethods";

export default {
  name: "BusinessPage",
  props: ["id"],
  data() {
    return {
      arp: {},
      owner: {},
      year: this.$u2d(new Date(), "YYYY"),
      dataset: {
        // rounds: this.months(),
        // models: this.months(),
        recognitions: {},
        transitions: {},
        roundviews: {},
        roundlikes: {}
      },
      rounds: {
        list: [],
        loaded: false,
        count: 0
      },
      models: {
        list: [],
        loaded: false,
        count: 0
      },
      recognitions: {
        list: [],
        loaded: false,
        count: 0,
        interval: "day",
        date: [undefined, undefined]
      },
      transitions: {
        list: [],
        loaded: false,
        count: 0,
        interval: "day",
        date: [undefined, undefined]
      },
      roundviews: {
        list: [],
        loaded: false,
        count: 0,
        interval: "day",
        date: [undefined, undefined]
      },
      roundlikes: {
        list: [],
        loaded: false,
        count: 0,
        interval: "day",
        date: [undefined, undefined]
      },
      preview: undefined,
      loading: true,
      rendering: false,
      slider: {
        slidesPerView: 3,
        breakpoints: {
          750: {
            slidesPerView: 1
          },
          990: {
            slidesPerView: 2
          },
          1100: {
            slidesPerView: 1
          },
          1470: {
            slidesPerView: 2
          }
        }
      }
    };
  },
  computed: {
    qrParams() {
      return `type=arr&id=${this.id}`;
    },
    phone() {
      return this.arp["phone"]
        ? this.arp["phone"].replace(
            /^(\d)(\d{3})(\d{3})(\d{2})(\d{2}).*/,
            "+$1 ($2) $3-$4-$5"
          )
        : undefined;
    },
    getMonths() {
      return this.months();
    }
  },
  mounted() {
    this.formatStartDate = formatStartDate;
    this.formatEndDate = formatEndDate;
    this.dataMapper = dataMapper;
    this.months = months;
    this.getStartDate = getStartDate;
    this.getEndDate = getEndDate;
    this.getDatasetKeys = getDatasetKeys;
    this.formatDate = formatDate;

    locale.use(this.$i18n.locale === "ru" ? ru : en);

    this.$navbar.name = this.$t("business");

    this.getArp();
    this.getRounds();
    this.getModels();
  },
  methods: {
    inviteAdmin() {
      this.$refs["arr-invite"].open(this.arp["idt_arp"]);
    },

    getArp() {
      return this.$api.v2
        .get("/arp", { params: { idt_arp: this.id } })
        .then(response => {
          console.log("response.data.idt_user", response.data);
          this.$api.v1
            .get("/user", { params: { id: response.data.arp.idt_user } })
            .then(response => {
              this.owner = response.data.user;
            });
          this.arp = response.data.arp;
          this.loading = false;

          this.$render(this.arp["logo"]).then(src => {
            this.preview = src;
          });

          this.$navbar.name = `${this.$t("business")} #${this.arp.idt_arp} | ${
            this.arp.name
          }`;
          return Promise.resolve();
        })
        .catch(() => {
          this.$router.replace("/404");
        });
    },

    getRounds() {
      let params = {
        limit: 500,
        idt_arp: this.id,
        is_location_hidden: true,
        sn_id: this.$store.getters.selectedSnId
      };

      this.$api.v2
        .get("/rounds", { params })
        .then(response => {
          let rounds = response.data.rounds;
          this.rounds.count = response.data.count;

          if (rounds.length > 6) rounds.length = 6;
          this.rounds.list = rounds;

          this.rounds.loaded = true;

          Object.keys(this.dataset.rounds).map(k => {
            this.dataset.rounds[k] = 0;
          });

          rounds.map(round => {
            let month = this.$u2d(round["a_time"], "MMM");
            let year = this.$u2d(round["a_time"], "YYYY");

            if (year === this.year) this.dataset.rounds[month]++;
          });
        })
        .catch(() => {
          this.rounds.loaded = true;
        });
    },

    getModels() {
      this.$api.v2
        .get("/models", {
          params: {
            idt_arp: this.id,
            limit: 6,
            sn_id: this.$store.getters.selectedSnId
          }
        })
        .then(response => {
          let models = response.data.models;
          this.models.count = response.data.count;

          if (models.length > 6) models.length = 6;
          this.models.list = models;

          this.models.loaded = true;

          Object.keys(this.dataset.models).map(k => {
            this.dataset.models[k] = 0;
          });

          models.map(round => {
            let month = this.$u2d(round["a_time"], "MMM");
            let year = this.$u2d(round["a_time"], "YYYY");

            if (year === this.year) this.dataset.models[month]++;
          });
        })
        .catch(() => {
          this.rounds.loaded = true;
        });
    },

    submit() {
      this.loading = true;

      let formData = new FormData();
      formData.append("idt_arp", this.arp["idt_arp"]);
      formData.append("description", this.arp["description"]);
      formData.append("name", this.arp["name"]);
      formData.append("phone", this.arp["phone"]);
      formData.append("site", this.arp["site"]);

      this.$api.v2
        .put("/arp/arp", formData, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.$alert.danger("Oops.. Server error");
        });
    },

    toggleArrActive() {
      let params = {
        idt_arp: this.arp["idt_arp"],
        blocked: !this.arp.blocked
      };

      this.$api.v2
        .put("/arp/block", params)
        .then(() => {
          this.getArp();
        })
        .catch(() => {
          this.$alert.danger("Oops.. Server error");
        });
    }
  },
  components: {
    ArrInvite,
    RoundCard,
    ModelCard,
    QrCode,
    Chart
  }
};
</script>
