var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "models-page" },
    [
      _c(
        "section",
        {
          staticClass: "border-bottom bg-lighter md-up:sticky-top",
          staticStyle: { top: "70px" }
        },
        [
          _c("div", { staticClass: "container py-3" }, [
            _c("nav", { staticClass: "nav sm-down:flex-column" }, [
              _c(
                "span",
                {
                  staticClass:
                    "btn btn-sm btn-light text-left d-block sm-down:w-fill",
                  on: { click: _vm.inviteAdmin }
                },
                [
                  _c("i", { staticClass: "i-user-check float-left" }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("set_owner")))])
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass:
                    "btn btn-sm btn-light text-left d-block sm-down:w-fill",
                  attrs: {
                    href: _vm.$store.state.isProd
                      ? "https://webarr.arround.business/" + _vm.id
                      : "https://webarr.gora.studio/" + _vm.id,
                    target: "_blank"
                  }
                },
                [
                  _c("i", { staticClass: "i-globe" }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("business_public_page")))])
                ]
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("section", [
        _c("div", { staticClass: "container py-5" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "md-down:col-12 lg:col-5 xl:col-3" },
              [
                _c("mega-card", [
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c("mega-image", {
                        staticClass: "rounded",
                        attrs: { ratio: "1x1", src: _vm.preview }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("nav", { staticClass: "nav flex-column" }, [
                    _c("div", { staticClass: "nav-text w-100 text-muted" }, [
                      _c("i", { staticClass: "i-globe" }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.arp["name"]))])
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        class: { "events-disable text-dark": !_vm.arp["site"] },
                        attrs: { href: _vm.arp["site"] }
                      },
                      [
                        _c("i", { staticClass: "i-link" }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.arp["site"] || "- - -"))])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        class: { "events-disable text-dark": !_vm.phone },
                        attrs: { href: "tel:" + _vm.phone }
                      },
                      [
                        _c("i", { staticClass: "i-phone" }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.phone || "- - -"))])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-body" }, [
                    _c(
                      "p",
                      {
                        staticClass: "mb-0",
                        staticStyle: {
                          "word-wrap": "break-word",
                          "line-height": "1.3"
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.arp.description) +
                            "\n              "
                        )
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("h4", { staticClass: "bold text-muted text-dark mb-4" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("business_owner")) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c("mega-card", [
                  _c("div", { staticClass: "card-body" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "w-100 navbar-label mr-auto text-dark",
                            attrs: {
                              to: {
                                name: "User",
                                params: { id: _vm.owner.idt_user }
                              }
                            }
                          },
                          [
                            _c("mega-image", {
                              staticClass: "rounded-full navbar-avatar mr-3",
                              staticStyle: { overflow: "hidden" },
                              attrs: {
                                small: "",
                                ratio: "1x1",
                                src: _vm.owner.avatar
                              }
                            }),
                            _vm._v(" "),
                            _c("div", {}, [
                              _c("div", [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(_vm.owner.name || "Noname") +
                                    "\n                    "
                                )
                              ]),
                              _vm._v(" "),
                              _c("small", [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(_vm.owner.email) +
                                    "\n                    "
                                )
                              ])
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm.arp.admins.admins.length > 0
                  ? _c(
                      "h4",
                      { staticClass: "bold text-muted text-dark mb-4" },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("business_admins")) +
                            "\n          "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.arp.admins.admins.length > 0
                  ? _c("mega-card", [
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        _vm._l(_vm.arp.admins.admins, function(admin, i) {
                          return _c(
                            "div",
                            {
                              key: i,
                              class: {
                                "mb-4":
                                  _vm.arp.admins.admins.length > 1 &&
                                  i + 1 < _vm.arp.admins.admins.length
                              }
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "w-100 navbar-label mr-auto text-dark",
                                  attrs: {
                                    to: {
                                      name: "User",
                                      params: { id: admin.idt_user }
                                    }
                                  }
                                },
                                [
                                  _c("mega-image", {
                                    staticClass:
                                      "rounded-full navbar-avatar mr-3",
                                    staticStyle: { overflow: "hidden" },
                                    attrs: {
                                      small: "",
                                      ratio: "1x1",
                                      src: admin.avatar
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("div", {}, [
                                    _c("div", [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(admin.name || "Noname") +
                                          "\n                    "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("small", [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(admin.username) +
                                          "\n                    "
                                      )
                                    ])
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("h4", { staticClass: "bold text-muted text-dark mb-4" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("qr_code")) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c("qr-code", { attrs: { uri: _vm.qrParams } }),
                _vm._v(" "),
                _vm.arp.blocked
                  ? _c(
                      "mega-button",
                      {
                        staticClass: "btn-primary rounded-0 w-100",
                        on: { click: _vm.toggleArrActive }
                      },
                      [_vm._v(_vm._s(_vm.$t("business_block_activate")))]
                    )
                  : _c(
                      "mega-button",
                      {
                        staticClass: "btn-danger rounded-0 w-100",
                        on: { click: _vm.toggleArrActive }
                      },
                      [_vm._v(_vm._s(_vm.$t("business_block_deactivate")))]
                    )
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "md-down:col-12 lg:col-7 xl:col-9" }, [
              _c("section", { staticClass: "no-select mb-5" }, [
                _c("div", { staticClass: "d-flex mb-4" }, [
                  _c("h4", { staticClass: "bold text-muted text-dark mb-0" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("activity")) +
                        "\n              "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "sm-down:col-12 md:col-6 lg:col-12 xl:col-6"
                    },
                    [
                      _c("chart", {
                        attrs: {
                          endpoint: "/stat/arp/recognitions",
                          idt_arp: _vm.id,
                          chartName: "ref_detects",
                          doubleGraph: false
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "sm-down:col-12 md:col-6 lg:col-12 xl:col-6"
                    },
                    [
                      _c("chart", {
                        attrs: {
                          endpoint: "/stat/arp/transitions",
                          idt_arp: _vm.id,
                          chartName: "arr_transitions",
                          doubleGraph: false
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "sm-down:col-12 md:col-6 lg:col-12 xl:col-6"
                    },
                    [
                      _c("chart", {
                        attrs: {
                          endpoint: "/stat/arp/roundviews",
                          idt_arp: _vm.id,
                          chartName: "round_views",
                          doubleGraph: false
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "sm-down:col-12 md:col-6 lg:col-12 xl:col-6"
                    },
                    [
                      _c("chart", {
                        attrs: {
                          endpoint: "/stat/arp/roundlikes",
                          idt_arp: _vm.id,
                          chartName: "round_likes",
                          doubleGraph: false
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _vm.rounds.loaded && _vm.rounds.count > 0
                ? _c(
                    "section",
                    { staticClass: "mb-4" },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c(
                            "h4",
                            { staticClass: "bold text-muted text-dark" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("last_rounds")) +
                                  "\n              "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            {
                              staticClass: "btn btn-sm ml-auto",
                              attrs: {
                                to: {
                                  name: "Business_Rounds",
                                  params: { arr_id: _vm.id }
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("view_all")))]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "mega-slider",
                        { staticClass: "row", attrs: { options: _vm.slider } },
                        _vm._l(_vm.rounds.list, function(round, i) {
                          return _c("mega-slide", { key: i }, [
                            _c(
                              "div",
                              { staticClass: "px-3" },
                              [
                                _c("round-card", {
                                  staticClass: "cursor-grab",
                                  attrs: { data: round }
                                })
                              ],
                              1
                            )
                          ])
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.models.loaded && _vm.models.count > 0
                ? _c(
                    "section",
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c(
                            "h4",
                            { staticClass: "bold text-muted text-dark" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("last_models")) +
                                  "\n              "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            {
                              staticClass: "btn btn-sm ml-auto",
                              attrs: {
                                to: {
                                  name: "Models",
                                  params: {
                                    roundType: "3d",
                                    is_active: "active"
                                  },
                                  query: { arr: _vm.id }
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("view_all")))]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "mega-slider",
                        { staticClass: "row", attrs: { options: _vm.slider } },
                        [
                          _vm._l(_vm.models.list, function(model, i) {
                            return [
                              model.modifications
                                ? _c("mega-slide", { key: i }, [
                                    _c(
                                      "div",
                                      { staticClass: "px-3" },
                                      [
                                        _c("model-card", {
                                          staticClass: "cursor-grab",
                                          attrs: { data: model }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("arr-invite", { ref: "arr-invite" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }